.react-table tbody {
  background: var(--white) !important;
}

.react-table tr:nth-child(even) {
  background-color: #f8f9fa;
}

.react-table th {
  padding-top: 5px !important;
  padding-bottom: 5px !important ;
  height: 49px !important;
  border-right: 1px solid #e0e0e0;
}

.react-table th:last-child {
  border-right: none;
}

.react-table tbody td {
  padding-top: 5px !important;
  padding-bottom: 5px !important ;
  height: 49px !important;
}

.react-table tbody tr {
  background: white;
}

.turn-off {
  transform: rotate(90deg);

  opacity: 0;
}

.fa-arrow-down {
  font-size: 13px;
  color: #989898;
  top: 17px;
  position: absolute;
  margin-left: 5px;
  transition: 0.2s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}
