.container {
  position: relative;
  box-shadow: 0 2px 6px var(--gray-shadow);
  font-size: 14px;
}

.header {
  display: flex;
  align-items: center;
  height: 40px;
  background: white;
  padding: 10px;
  border-bottom: 1px solid var(--gray-border);
  z-index: 10;
}

.title {
  font-weight: 500;
  margin-left: 50px;
}

.list {
  position: relative;
  overflow-y: auto;
  max-height: 80vh;
  min-height: 80vh;
  background: white;
}

.indicator {
  position: absolute;
  font-size: 13px;
  font-weight: normal;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 575px) {
  .title {
    margin-left: 40px;
  }
}
