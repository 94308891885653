.card {
  display: flex;
  gap: 10px;
  background: white;
  align-items: center;
  border-bottom: 1px solid var(--gray-border);
  padding: 7px 16px;
  font-size: 14px;
  flex-wrap: wrap;
}

.card:nth-child(even) {
  background: #f8f9fa;
}

.order {
  color: var(--gray-text);
  min-width: 30px;
  grid-area: index;
}

.failId {
  position: relative;
  display: flex;
  gap: 3px;
  font-weight: 500;
  min-width: 70px;
  padding: 3px 5px;
  grid-area: id;
  cursor: text;
}

.failId span {
  align-self: center;
  margin-bottom: 3px;
  font-size: 10px;
}

/*.failId:before {*/
/*  position: absolute;*/
/*  content: 'ID';*/
/*  top: 0;*/
/*  left: -13px;*/
/*  font-size: 10px;*/
/*}*/

.title {
  font-size: inherit;
  font-weight: normal;
  color: var(--blue-dark);
  text-underline-offset: 3px;
  grid-area: address;
  cursor: text;
}

.ipAddress {
  color: var(--gray-text);
  grid-area: ip;
  cursor: text;
}

@media screen and (max-width: 940px) {
  .card {
    display: grid;
    padding: 7px 20px;
    grid-auto-columns: auto auto 1fr;
    grid-template-areas: 'index id ip' 'address address address';
  }
  .title {
    margin-left: 40px;
  }
  .failId {
    margin-right: 10px;
  }
}

@media screen and (max-width: 575px) {
  .order {
    min-width: 20px;
  }
  .title {
    margin-left: 30px;
  }
}
