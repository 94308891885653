body {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  background: var(--white);
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
}

nav:after {
  content: "";
  clear: both;
  display: table;
}

.logo {
  float: left;
  color: black;
  margin-right: 90px;
  font-size: 24px;
  font-weight: 500;
  line-height: 60px;
  padding-left: 60px;
}

nav ul {
  float: left;
  list-style: none;
  /*margin-right: 40px;*/
  position: relative;
  z-index: 100;
}

nav ul li {
  /*float: left;*/
  display: inline-block;
  /*background: #f8f9fa;*/
  margin: 0 5px;
}

nav ul li a {
  color: black;
  text-decoration: none;
  line-height: 60px;
  font-size: 17px;
  padding: 8px 15px;
}

nav ul ul li a {
  font-size: 14px;
}

nav ul li a:hover {
  /*color: cyan;*/
  border-radius: 2px;
  box-shadow: 0 0 5px #bebebe, 0 0 5px #d4eeee;
}

nav ul ul {
  position: absolute;
  top: 90px;
  border: 1px solid #d2d2d2;
  border-top: 2px solid #fabc02;
  background: white;
  border-radius: 2px;
  opacity: 0;
  width: max-content;
  visibility: hidden;
  transition: top 0.3s;
}

nav ul ul ul {
  border-top: none;
}

nav ul li:hover > ul {
  top: 60px;
  opacity: 1;
  visibility: visible;
}

nav ul li:hover .nohover {
  transform: rotate(180deg);
}

nav ul ul li a:hover {
  box-shadow: none;
}

nav ul ul li {
  position: relative;
  margin: 0px;
  padding: 0;
  float: none;
  display: list-item;
}

nav ul ul li:hover {
  background: #edf4fb;
}

nav ul ul li:last-child {
  border-bottom: none;
}

nav ul ul li a {
  line-height: 40px;
}

nav ul ul ul li {
  position: relative;
  top: -70px;
  left: 150px;
}

.fa-plus {
  margin-left: 40px;
  font-size: 15px;
}

.show,
.icon,
nav input {
  display: none;
}

.fa-caret-down {
  font-size: 14px;
  transition: 0.4s ease-out;
}

@media all and (max-width: 968px) {
  nav ul {
    margin-right: 0;
    float: left;
  }

  .logo {
    margin-right: 0;
    padding-left: 30px;
    width: 100%;
  }

  nav ul li,
  nav ul ul li {
    display: block;
    width: 100%;
  }

  nav ul ul {
    position: static;
    border-left: none;
    border-right: none;
    display: none;
    opacity: 0;
    visibility: visible;
    width: 100vw;
  }

  nav ul ul ul li {
    position: static;
  }

  nav ul ul li {
    border-bottom: 1px solid #d9d9d9;
  }

  nav ul ul a {
    padding-left: 40px;
  }

  nav ul ul ul a {
    padding-left: 80px;
  }

  .show {
    display: block;
    font-size: 18px;
    padding: 0 20px;
    line-height: 70px;
    cursor: pointer;
  }

  .icon {
    display: block;
    color: black;
    position: absolute;
    right: 40px;
    line-height: 60px;
    font-size: 25px;
    cursor: pointer;
  }

  nav ul li a:hover {
    box-shadow: none;
  }

  .show + a {
    display: none;
  }

  .nav-block ul {
    display: none;
  }

  .fa-caret-down {
    margin-left: 5px;
  }

  .arrow-active {
    transform: rotate(180deg);
  }

  [id^="btn"]:checked + ul {
    opacity: 1;
    display: block;
  }
}
